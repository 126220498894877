import { TopicID } from "./types";

interface Topic {
  id: TopicID;
  name: string;
}

export const TOPICS: Topic[] = [
  {
    id: TopicID.EmployeeBenefitPlans,
    name: "Parent Pass",
  },
  {
    id: TopicID.HelpSignUp,
    name: "Need help signing up",
  },
  {
    id: TopicID.HelpLoginIn,
    name: "Need help logging in",
  },
  {
    id: TopicID.HelpResetPassword,
    name: "Need help resetting my password",
  },
  {
    id: TopicID.HelpFindSchool,
    name: "Need help claiming my school",
  },
  {
    id: TopicID.ChangeAddress,
    name: "Need help updating school address",
  },
  {
    id: TopicID.ChangeAdministrator,
    name: "Need help updating school administrator",
  },
  {
    id: TopicID.HelpFindSchool,
    name: "Need help finding my school",
  },
  {
    id: TopicID.HelpAddSchool,
    name: "Need help adding my school to the platform",
  },
  {
    id: TopicID.AboutTiers,
    name: "School tier questions",
  },
  {
    id: TopicID.TourRequest,
    name: "School profile questions",
  },
  {
    id: TopicID.AddSpotlight,
    name: "Provider spotlight questions",
  },
  {
    id: TopicID.PdoTourRequest,
    name: "Childcare reservation service questions",
  },
  {
    id: TopicID.FeatureSuggestion,
    name: "New feature suggestion",
  },
  {
    id: TopicID.BugReport,
    name: "System bug report",
  },
  {
    id: TopicID.Other,
    name: "Other",
  },
];
