import React, { useRef, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useForm, Controller, FieldError } from "react-hook-form";
import qs from "query-string";
import omitBy from "lodash/omitBy";
import isNil from "lodash/isNil";
import DeleteIcon from "@material-ui/icons/Delete";
import DialogActions from "@material-ui/core/DialogActions";

import { createCss } from "./styles";
import { OutlinedInput, Input } from "@ui-kit/InputFields";
import Dialog from "@ui-kit/Dialog";
import Typography from "@ui-kit/Typography";
import Button from "@ui-kit/Button";
import HttpService from "@services/api/HttpService";
import { ROOT_API_V1 } from "@constants";
import SuccessStep from "@components/SuccessStep";
import { ControlledPhoneInput } from "@ui-kit/PhoneInput";
import { useNotificationStore } from "@store/NotificationStore";
import { t } from "@i18n";
import { useAppStore } from "@store/AppStore";
import { useUserStore } from "@store/UserStore";
import Select from "@ui-kit/Select";
import { Spacer } from "@ui-kit/Spacer";
import { required, email } from "@validators";
import { goToFormError } from "@helpers/goToFormError";
import { TOPICS } from "./topics";
import { ShadowDivider } from "@components/ShadowDivider";
import { TopicID, isTopicID } from "./types";
import { Models } from "@services/api";

const TOUR_REQUEST_TEXT =
  "We are looking to maximize parent engagement with our school and would like to schedule a " +
  "guided profile tour.  Please contact our school to coordinate a time.";
const PDO_TOUR_REQUEST_TEXT =
  "We are interested in learning more about the Parent Day Out reservation service. " +
  "Please contact our school to schedule a guided tour.";
const LOG_IN_TEXT = "I am receiving an error when logging in. Please assist.";
const PARENT_PASS_TEXT =
  "Our company / organization is interested in learning more about the Parent Pass Program.\n\nPlease email us a service brochure link.";
const DEFAULT_TEXTS = [
  TOUR_REQUEST_TEXT,
  PDO_TOUR_REQUEST_TEXT,
  LOG_IN_TEXT,
  PARENT_PASS_TEXT,
];

interface FormFields {
  topic: string;
  description: string;
  name: string;
  phone: Models.Phone;
  email: string;
}

const MAX_SIZE = 9437184;

const SupportForm: React.FC = () => {
  const [{ realLocation }] = useAppStore();
  const [{ user }] = useUserStore();
  const history = useHistory();
  const { search } = useLocation();
  const [, { setNotification }] = useNotificationStore();
  const [facilityId, setFacilityId] = useState<string>();
  const [submitted, setSubmitted] = useState(false);
  const input1Ref = useRef<HTMLInputElement>(null);
  const input2Ref = useRef<HTMLInputElement>(null);

  const { control, formState, setValue, watch, handleSubmit, errors } =
    useForm<FormFields>({
      shouldUnregister: false,
    });
  const [deleteDialog, setDeleteDialog] = useState<"file1" | "file2">();
  const [file1, setFile1] = useState<File | null>(null);
  const [file2, setFile2] = useState<File | null>(null);

  const css = createCss();

  useEffect(() => {
    const query = qs.parse(search);

    if (query.facilityId) {
      setFacilityId(query.facilityId as string);
    }

    if (query.topic) {
      if (query.topic === TopicID.PdoTourRequest) {
        setValue("description", PDO_TOUR_REQUEST_TEXT);
      }
      if (query.topic === TopicID.TourRequest) {
        setValue("description", TOUR_REQUEST_TEXT);
      }
      if (query.topic === TopicID.HelpLoginIn) {
        setValue("description", LOG_IN_TEXT);
      }
      if (query.topic === TopicID.EmployeeBenefitPlans) {
        setValue("description", PARENT_PASS_TEXT);
      }
      if (typeof query.topic === "string" && isTopicID(query.topic)) {
        setValue("topic", query.topic);
      }
    }

    if (query.email) {
      setValue("email", query.email);
    }

    history.replace({
      search: qs.stringify({
        ...query,
        facilityId: undefined,
        topic: undefined,
        email: undefined,
      }),
    });
  }, []);

  function closeModal() {
    history.replace({
      pathname: realLocation.current.pathname,
      search: realLocation.current.search,
    });
  }

  function handleFileUpload(
    event: React.ChangeEvent<HTMLInputElement>,
    type: "file1" | "file2",
  ) {
    const { files } = event.target;

    if (files && files.length) {
      const file = files[0];

      if (file.size > MAX_SIZE) {
        setNotification({
          message: "Large file size: (9MB max)",
          type: "error",
        });
        return;
      }

      if (type === "file1") {
        setFile1(file);

        return;
      }

      setFile2(file);
    }
  }

  const renderDeleteDialog = () => {
    return (
      <Dialog open onClose={() => setDeleteDialog(undefined)}>
        <Typography bolded paragraph>
          Remove file?
        </Typography>

        <DialogActions>
          <Button onClick={() => setDeleteDialog(undefined)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (deleteDialog === "file1") {
                setFile1(null);
              }

              if (deleteDialog === "file2") {
                setFile2(null);
              }

              setDeleteDialog(undefined);
            }}
            variant="contained"
            color="error"
            data-test="confirm-remove-file"
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const submit = handleSubmit(
    async ({ topic, description, name, phone, email }) => {
      const files = [];

      if (file1) {
        files.push(file1);
      }

      if (file2) {
        files.push(file2);
      }

      const toSubmit = omitBy(
        {
          topic: TOPICS.find((t) => t.id === topic)?.name || "",
          description,
          "files[]": files,
          userEmail: user?.email || email,
          userName: user ? `${user.firstName} ${user.lastName}` : name,
          userPhone: user?.phone?.number || phone?.number,
          userPhoneCountry: user?.phone?.country || phone?.country,
        },
        (v) => isNil(v),
      );

      if (facilityId) {
        toSubmit.facilityId = facilityId;
      }

      await HttpService.post(ROOT_API_V1 + "/support", toSubmit, "file", true, {
        "Content-Type": "multipart/form-data",
      });

      setSubmitted(true);
    },
    () => setTimeout(goToFormError, 0),
  );

  const renderForm = () => (
    <>
      {!user && (
        <>
          <Controller
            render={(controllerProps) => (
              <Input
                {...controllerProps}
                error={errors.name?.message}
                label="Name"
                disabled={formState.isSubmitting}
              />
            )}
            rules={required()}
            name="name"
            control={control}
            defaultValue=""
          />

          <ControlledPhoneInput
            error={(errors.phone as FieldError)?.message}
            label="Phone"
            disabled={formState.isSubmitting}
            name="phone"
            control={control}
          />

          <Controller
            render={(controllerProps) => (
              <Input
                {...controllerProps}
                error={errors.email?.message}
                label="Email"
                type="email"
                disabled={formState.isSubmitting}
              />
            )}
            rules={{
              ...required(),
              ...email(),
            }}
            defaultValue=""
            name="email"
            control={control}
          />

          <Spacer size="large" />
        </>
      )}

      <Controller
        render={({ onChange, ...controllerProps }) => (
          <Select
            {...controllerProps}
            onChange={(event) => {
              onChange(event);

              if (event.value.id === TopicID.TourRequest) {
                if (
                  !watch("description") ||
                  DEFAULT_TEXTS.includes(watch("description"))
                ) {
                  setValue("description", TOUR_REQUEST_TEXT);
                }

                return;
              }

              if (event.value.id === TopicID.PdoTourRequest) {
                if (
                  !watch("description") ||
                  DEFAULT_TEXTS.includes(watch("description"))
                ) {
                  setValue("description", PDO_TOUR_REQUEST_TEXT);
                }
              }

              if (event.value.id === TopicID.HelpLoginIn) {
                if (
                  !watch("description") ||
                  DEFAULT_TEXTS.includes(watch("description"))
                ) {
                  setValue("description", LOG_IN_TEXT);
                }
              }

              if (event.value.id === TopicID.EmployeeBenefitPlans) {
                if (
                  !watch("description") ||
                  DEFAULT_TEXTS.includes(watch("description"))
                ) {
                  setValue("description", PARENT_PASS_TEXT);
                }
              }

              if (DEFAULT_TEXTS.includes(watch("description"))) {
                setValue("description", "");
              }
            }}
            error={errors.topic?.message}
            items={TOPICS}
            label="Select support topic"
            labelProp="name"
          />
        )}
        defaultValue=""
        name="topic"
        control={control}
        rules={{
          ...required(),
          validate: () => {
            if (watch("topic") === "bug-report") {
              if (
                !input1Ref.current?.files?.length &&
                !input2Ref.current?.files?.length
              ) {
                return "Attachment required for this topic";
              }
            }
            return true;
          },
        }}
      />
      <Spacer size="medium" />

      {!!watch("topic") && (
        <>
          <Typography variant="h6">
            {t("childcareControlPanel.supportForm.descriptionLabel")}
          </Typography>
          <Controller
            render={(controllerProps) => (
              <OutlinedInput
                {...controllerProps}
                multiline
                placeholder="Please specify..."
                rowsMax="6"
                data-test="description-field"
                maxChars={750}
                error={errors.description?.message}
              />
            )}
            defaultValue=""
            name="description"
            control={control}
            rules={required()}
          />
        </>
      )}

      <Typography variant="h6" css={css.subtitle} paragraph>
        {t("childcareControlPanel.supportForm.filesLabel")}
      </Typography>

      <Typography variant="body1" paragraph>
        <b>File size:</b> up to 9MB
      </Typography>

      <div css={css.fileWrapper}>
        {!file1 ? (
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => input1Ref.current?.click()}
            data-test="choose-file-btn-1"
          >
            Choose file
          </Button>
        ) : (
          <>
            <a
              target="_blank"
              rel="noreferrer"
              href={URL.createObjectURL(file1)}
              data-test="file-link-1"
            >
              Supporting document
            </a>
            <DeleteIcon
              className="delete-icon"
              onClick={() => setDeleteDialog("file1")}
            />
          </>
        )}
      </div>

      <div css={css.fileWrapper}>
        {!file2 ? (
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => input2Ref.current?.click()}
            data-test="choose-file-btn-2"
          >
            Choose file
          </Button>
        ) : (
          <>
            <a
              target="_blank"
              rel="noreferrer"
              href={URL.createObjectURL(file2)}
              data-test="file-link-2"
            >
              Supporting document
            </a>
            <DeleteIcon
              className="delete-icon"
              onClick={() => setDeleteDialog("file2")}
            />
          </>
        )}
      </div>

      <input
        type="file"
        accept="image/png,image/jpeg,application/pdf"
        ref={input1Ref}
        onChange={(event) => handleFileUpload(event, "file1")}
        css={css.fileInput}
        data-test="file-input-1"
      />

      <input
        type="file"
        accept="image/png,image/jpeg,application/pdf"
        ref={input2Ref}
        onChange={(event) => handleFileUpload(event, "file2")}
        css={css.fileInput}
        data-test="file-input-2"
      />

      <Button
        variant="contained"
        color="primary"
        fullWidth
        size="large"
        onClick={submit}
        data-test="submit-support-request-btn"
        loading={formState.isSubmitting}
      >
        {t("childcareControlPanel.supportForm.submitButton")}
      </Button>
    </>
  );

  return (
    <Dialog
      open
      onClose={closeModal}
      title="Contact support"
      data-test="support-dialog"
    >
      {deleteDialog && renderDeleteDialog()}
      <div css={css.container}>
        {!submitted && renderForm()}

        {submitted && (
          <SuccessStep
            text="Submitted"
            content={
              <>
                <ShadowDivider />
                <div css={css.action}>
                  <Button
                    variant="contained"
                    color="primary"
                    data-test="done-button"
                    size="large"
                    onClick={closeModal}
                  >
                    Done
                  </Button>
                </div>
              </>
            }
          />
        )}
      </div>
    </Dialog>
  );
};

export default SupportForm;
