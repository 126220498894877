import values from "lodash/values";

export type Classes =
  | "container"
  | "subtitle"
  | "fileInput"
  | "fileWrapper"
  | "action";

export enum TopicID {
  TourRequest = "tour-request",
  PdoTourRequest = "pdo-tour-request",
  AboutTiers = "about-tiers",
  AddSpotlight = "add-spotlight",
  ChangeAdministrator = "change-administrator",
  ChangeAddress = "change-address",
  HelpFindSchool = "help-find-school",
  HelpClaimSchool = "help-claim-school",
  HelpAddSchool = "help-add-school",
  FeatureSuggestion = "feature-suggestion",
  BugReport = "bug-report",
  Other = "other",
  EmployeeBenefitPlans = "employee-benefit-plans",
  HelpLoginIn = "help-login",
  HelpSignUp = "help-sign-up",
  HelpResetPassword = "help-reset-password",
}

export const isTopicID = (str: string): str is TopicID => {
  return values(TopicID).includes(str as TopicID);
};
