import axios, { AxiosRequestConfig } from "axios";
import { v4 } from "uuid";
import log from "loglevel";

import { NetworkError } from "./HttpError";

const createFormData = (data: any) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    const value = data[key];

    if (Array.isArray(value)) {
      value.forEach((v) => formData.append(key, v));
    } else {
      formData.append(key, value);
    }
  });

  return formData;
};

const successStatuses = [200, 201, 202];

class HttpService {
  private headers = {
    Accept: "application/json",
  };

  public get = async <T>(
    url: string,
    params?: { [key: string]: any },
    withToken = true,
  ): Promise<T> => {
    const config: AxiosRequestConfig = {
      headers: {
        ...this.headers,
      },
      method: "GET",
      params,
      url,
    };

    return this.makeRequest<T>(config);
  };

  public delete(url: string, withToken = true): Promise<any> {
    const config: AxiosRequestConfig = {
      headers: {
        ...this.headers,
      },
      method: "DELETE",
      url,
    };

    return this.makeRequest(config);
  }

  public post = async <T>(
    url: string,
    data?: { [key: string]: any },
    type?: string,
    withToken = true,
    headers?: { [key: string]: any },
  ): Promise<T> => {
    const config: AxiosRequestConfig = {
      headers: {
        ...this.headers,
        ...headers,
      },
      method: "POST",
      url,
    };

    if (type === "file") {
      config.data = createFormData(data);
    } else {
      config.data = JSON.stringify(data);
      config.headers["Content-Type"] = "application/json";
    }

    return this.makeRequest<T>(config);
  };

  public put = async <T>(
    url: string,
    data: { [key: string]: any },
    type?: string,
    withToken = true,
  ): Promise<T> => {
    const config: AxiosRequestConfig = {
      headers: {
        ...this.headers,
      },
      method: "PUT",
      url,
    };

    if (type === "file") {
      config.data = createFormData(data);
    } else {
      config.data = JSON.stringify(data);
      config.headers["Content-Type"] = "application/json";
    }

    return this.makeRequest<T>(config);
  };

  private makeRequest = async <T>(config: AxiosRequestConfig) => {
    const requestId = v4();

    try {
      log.debug(`Request sent: ${config.method} : ${config.url}`, {
        data: config.data,
        id: requestId,
        params: config.params,
      });

      const response = await axios(config);

      log.debug(`Response recieved: ${response.status}`, {
        data: response.data,
        id: requestId,
      });

      if (successStatuses.includes(response.status)) {
        return response.data as T;
      }

      throw new NetworkError(response);
    } catch (error) {
      log.error("Request failed", {
        data: error.response ? error.response.data : undefined,
        error,
      });

      throw error;
    }
  };
}

export { HttpService };
export default new HttpService();
