import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  container: (theme) => ({
    position: "relative",

    [theme.mediaRequests.notMobile]: {
      width: 400,
    },
  }),

  subtitle: {
    marginTop: 20,
  },

  fileInput: {
    display: "none",
  },

  fileWrapper: (theme) => ({
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 15,

    "& .delete-icon": {
      color: theme.palette.error.main,
      cursor: "pointer",
    },
  }),

  action: {
    display: "flex",
    justifyContent: "center",
    marginTop: 45,

    "& button": {
      minWidth: 100,
    },
  },
});
